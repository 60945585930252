class changeDeparment{
    constructor(select){
        this.select = select;
        this.panels = $('.panel-support-hours');
        this.body = $('.main-body');
        this.bindEvents();
    }
    bindEvents(){
        $(this.select).on('change', this.changeDeparment.bind(this));
    }
    changeDeparment(){
        let value = $(event.currentTarget)[0].value,
            currentDepartmentPanel = $('.panel-support-hours-department-'+value),
            allDepartmentsPanel = $('.panel-support-hours-department-all');
        this.panels.each(function(){
            $(this)[0].classList.add('hidden');
        });
        if (currentDepartmentPanel.length){
            currentDepartmentPanel.each(function(){
                $(this)[0].classList.remove('hidden');
            })
        }
        if (allDepartmentsPanel.length){
            allDepartmentsPanel.each(function(){
                $(this)[0].classList.remove('hidden');
            })
        }
        
        if (this.body[0].classList.contains('toggle-sidebar')){
            let canHideSidaber = true;
            this.panels.each(function(){
                if (!$(this)[0].classList.contains('hidden')){
                    canHideSidaber = false;
                }
            })
            if (canHideSidaber){
                this.body[0].classList.add('hidden-sidebar');
            }
            else{
                this.body[0].classList.remove('hidden-sidebar');
            }
        };
    }
}

export default {
    init() {
        $('#inputDepartment').each(function () {
            new changeDeparment($(this));
        });
    }
};